import React, { useEffect, useRef, useState } from "react";
import logo from "./images/logo_1_image_transparent.png";
import logoText from "./images/logo_1_text.png";
import { Terminal } from "primereact/terminal";
import { TerminalService } from "primereact/terminalservice";
import CanvasComponent from './components/canvas-background';

import "./App.css";

const programmerJokes: string[] = [
  "😎 Why do programmers prefer dark mode? Because the light attracts bugs! 🐛",
  "💡 How many programmers does it take to change a light bulb? None, that's a hardware problem. 💻",
  "👓 Why do Java developers wear glasses? Because they can't C#! 🤓",
  "😒 Why was the developer unhappy at their job? They wanted arrays. 🥲",
  "🛒 A programmer's wife tells him to go to the store: 'Buy a loaf of bread, and if they have eggs, buy a dozen.' He comes home with 12 loaves of bread. 🍞",
  "🍻 What's a programmer's favorite hangout place? Foo Bar. 😄",
  "🐍 Why do Python developers prefer snake_case? Because it’s easier to slither through the code. 🐍",
  "🍫 I told my computer I needed a break, and now it won’t stop sending me KitKats. 😂",
  "🌲 Why do programmers hate nature? It has too many bugs. 🐜",
  "🎃 Why do programmers always mix up Christmas and Halloween? Because Oct 31 == Dec 25. 🎄",
];

const getRandomJoke = (): string => {
  const randomIndex = Math.floor(Math.random() * programmerJokes.length);
  return programmerJokes[randomIndex];
};

function App() {
  const divRef = useRef<HTMLDivElement>(null);
  const [_prompt, setPrompt] = useState<string>("the-dev-space-$");
  const [streamData, setStreamData] = useState<string>('');



  useEffect(() => {

    const callTextGenerationService = async (command: string) => {
      try {
          const response = await fetch('/api/text', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'text/event-stream', // Accept event-stream
              },
              body: JSON.stringify({
                  messages: [{ role: 'user', content: command }],
                  stream: true, // Enable streaming
              }),
          });

          if (!response.ok) {
              throw new Error('Failed to connect to the text generation service');
          }

          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          let done = false;
          let streamData = '';
          while (!done) {
              const { value, done: readerDone } = await reader?.read() || {};
              if (readerDone) break;
              done = readerDone as any;
              const chunkValue = decoder.decode(value, { stream: !readerDone });
              setStreamData((prev) => prev + chunkValue);
              if(chunkValue == "[DONE]") break;
              const chunk = JSON.parse(chunkValue.split("data: ")[1]);
              streamData += chunk.response;
              TerminalService.emit("response", streamData);
          }
      } catch (error) {
          console.error('Error while calling text generation service:', error);
      }
  };
  
    const callTextGenerationServiceDirect = async (messageContent: string) => {
      try {
        const response = await fetch('https://codeplant-llm.codeplant.workers.dev/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/event-stream', // Accept event-stream
            },
            body: JSON.stringify({
                messages: [{ role: 'user', content: messageContent }],
                stream: true, // Enable streaming
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to connect to the text generation service');
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let done = false;

        while (!done) {
            const { value, done: readerDone } = await reader?.read() || {};
            done = readerDone as any;
            const chunkValue = decoder.decode(value, { stream: !readerDone });
            setStreamData((prev) => prev + chunkValue);
            TerminalService.emit("response", chunkValue);
        }
    } catch (error) {
        console.error('Error while calling text generation service:', error);
    }
    };
  
    const getTextGenerationService = () => {
      const url = window.location.href;
      if (url.includes("localhost:300")) {
        return callTextGenerationServiceDirect;
      } else {
        return callTextGenerationService;
      }
    };
    const commandHandler = (text: string) => {
      let response: string = "";
      let argsIndex: number = text.indexOf(" ");
      let command: string =
        argsIndex !== -1 ? text.substring(0, argsIndex) : text;
      setPrompt("the-dev-space-$");
      switch (command) {

        case 'help':
          response = "🤖 Available commands:" +
            "📅 (date) - Get the current date" +
            "🃏 (joke) - Get a random programmer joke" +
            "🔀 (random) - Get a random number between 0 and 100 \n" +
            "🗑️ (clear) - Clear the terminal \n" +
            "🤖 Type a message to get a response from the AI service. \n";

        break
        case "date":
          response = "📅 Today is " + new Date().toDateString();
          break;

        case "joke":
          response = getRandomJoke();
          break;

        case "random":
          response = Math.floor(Math.random() * 100).toString();
          break;

        case "clear":
          response = "";
          break;

        default:
          response = "🤖 Processing message...";
          const service = getTextGenerationService();
          service(text)
          break;
      }

      if (response) TerminalService.emit("response", response);
      else TerminalService.emit("clear");
    };
    TerminalService.on("command", commandHandler);

    return () => {
      TerminalService.off("command", commandHandler);
    };
  }, []);

  return (
    <>
    <CanvasComponent />
    <div className="App">
      <img
        src={logo}
        className="App-logo"
        alt="logo"
        style={{ marginTop: 10 }}
      />
      <img src={logoText} alt="logo" />
      <br />
      <div
        ref={divRef as any}
        id="terminalwrapper"
        style={{
          width: "50%",
          margin: "auto",
          height: "600px",
          textAlign: "left",
        }}
      >
        <hr />
        <p></p>
        <Terminal
          pt={{
            root: {
              color: "white",
              fontWeight: "bold",
              fontSize: 25,
            },
          }}
          prompt={_prompt}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            color: "white",
            fontSize: 25,
          }}
        />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            color: "white",
            marginTop: "10px",
          }}
        >
          © 2024 |{" "}
          <a
            href="https://www.youtube.com/@thedevspacesa"
            rel="noreferrer"
            target="_blank"
          >
            The Dev Space Podcast
          </a>{" "}
          | <a href="mailto:info@thedevspace.net">contact us</a>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
