import React, { useRef, useEffect } from 'react';

const CanvasComponent: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);


  useEffect(() => {
    const _points:{x:number, y:number}[] = [];

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const draw = () => {
          if (!ctx) return;
          ctx.globalAlpha = 0.95;
          // Example abstract pattern: randomly placed circles
          ctx.fillStyle = `rgb(43, 237, 245)`;
          ctx.beginPath();
          let _x = Math.random() * canvas.width;
            let _y =Math.random() * (canvas.height / 2) + canvas.height / 2;
            ctx.arc(_x, _y, Math.random() * 25, 0, Math.PI * 2);
            _points.push({x: _x, y: _y});
          
          ctx.fill();
        };

        const drawLine = () => {
            if (!ctx) return;
            // get a random 3 points from the _points array
            const _selectedPoints = [];
            for (let i = 0; i < 2; i++) {
                const _index = Math.floor(Math.random() * _points.length);
                _selectedPoints.push(_points[_index]);
            }
            ctx.globalAlpha = 0.95;
            ctx.strokeStyle = `rgb(255, 255, 255)`;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(_selectedPoints[0].x, _selectedPoints[0].y);
            for (let i = 1; i < _selectedPoints.length; i++) {
                ctx.lineTo(_selectedPoints[i].x, _selectedPoints[i].y);
            }
            ctx.stroke();
            ctx.globalAlpha = 1;
        }

        // Continuously draw on the canvas
        const intervalId = setInterval(draw, 1000);
        const intervalId2 = setInterval(drawLine, 5000);
        // Cleanup on component unmount
        return () => {clearInterval(intervalId);clearInterval(intervalId2)};
      }
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1, // Ensure it's behind other elements,
        height: '95vh',
        width: '100vw',
      }}
    />
  );
};

export default CanvasComponent;
